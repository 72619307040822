<template>
  <div>
    <el-form :model="form"
             ref="formRef"
             label-width="100px">
      <el-row :gutter="10"
              style="padding-bottom:10px">
        <el-col :span="6">
          <el-form-item label="起始时间">
            <el-date-picker v-model="form.begin_time"
                            type="datetime"
                            style="width:100%"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="截止时间">
            <el-date-picker v-model="form.end_time"
                            type="datetime"
                            default-time="23:59:59"
                            style="width:100%"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择日期时间" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-button type="primary"
                     @click="search">提交</el-button>
        </el-col>
      </el-row>
    </el-form>
    <Table ref="TablesRef"
           :tableData="tableData" />
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
    <!-- <p>
      注：题目类型按各科统计，不同试卷地区的相同科目可能有不同的题目类型
    </p>
    <p>
      2021新中考8省市中的语数外三科分别使用的是新中考I卷（山东卷）和新中考II卷（海南卷）
    </p> -->
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [],
      form: {},
      pageName: 'change_info_admin',
      tableColumns: [
        { prop: 'user_paper_id', align: 'center', label: 'ID', width: '', showToopic: false },
        { prop: 'subject_name', align: 'center', label: '科目', width: '130', showToopic: false },
        { prop: 'template_name', align: 'center', label: '模板名称', width: '120', showToopic: false },
        { prop: 'judge_end_time', align: 'center', label: '试卷批改提交时间', width: '', showToopic: false },
        { prop: 'verifiedMobile', align: 'center', label: '学生手机号', width: '', showToopic: false },
        { prop: 'nickname', align: 'center', label: '学生真实姓名', width: '', showToopic: false },
        { prop: 'paper_time', align: 'center', label: '学生试卷提交时间', width: '', showToopic: false },
        { prop: 'school_name', align: 'center', label: '所在学校', width: '', showToopic: false },
        {
          prop: 'd', align: 'center', label: '操作', width: '', type: 'img', btns: [
            { content: '试卷内容', type: 'look', event: 'showForm' },
          ]
        },
      ],
      page: 1,
      size: 10,
      totalElements: 0,
    }
  },
  created () {
  },
  mounted () {
    this.form = JSON.parse(this.$route.query.form)
    this.search()
    this.setTablesColums()
  },
  methods: {
    search () {
      this.$refs.pagination.toFirstPage()
    },
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    initData (page, limit) {
      if (JSON.stringify(this.form) == '{}') {
        return
      }
      let form = JSON.parse(JSON.stringify(this.form))
      form.page = page
      form.limit = limit
      this.$http({
        url: '/api/v2/stats/correct_detail',
        method: 'get',
        params: form
      }).then(res => {
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },
    showForm (row) {
      this.$router.push('/changeAdmin/paperlist/paperInfo?user_paper_id=' + row.user_paper_id)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>